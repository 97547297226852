import axios from 'axios'

export const uploadImageToCloud = async (file,id) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('id', id)
    try{
        const { data } = await axios.post(`${process.env.REACT_APP_API}/private/common/upload-image`, formData)
        return data
    }
    catch(error){
        return error
    }
}
