


  ///    SIGNIN REDUCER    ///
  import {
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_FAILED,
    ADMIN_LOGOUT,
  } from '../constants/adminConstants'


  const initialStateSignin = {
    loading: false,
    adminInfo: null,
    error: null,
    isAuthenticated:false
    }
export const adminSigninReducer = (
    state = {initialStateSignin},
    action
  ) => {
    switch (action.type) {
      case ADMIN_SIGNIN_REQUEST:
        return { ...state, loading: true}
      case ADMIN_SIGNIN_SUCCESS:
        return {
            ...state,
          loading: false,
          isAuthenticated: true,
          adminInfo: action.payload
        }
      case ADMIN_SIGNIN_FAILED:
        return {
            ...state,
          loading: false,
          adminInfo: null,
          isAuthenticated:false,
          error: action.payload
        }
  
      case ADMIN_LOGOUT:
        return {
            ...state,
          adminInfo: null
        }
      default:
        return state
    }
  }