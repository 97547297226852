import { Container, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PersonIcon from "@material-ui/icons/Person";

import { useStyles } from "./dashboard.style";
import Users from "../../components/Users";
import { DashboardLogic } from "./dashboard";
import { UserLogic } from "../../components/Users/userLogic";


function Dashboard() {
    const classes = useStyles();
    const { details } = DashboardLogic();
    const {
        users
    } = UserLogic();

    return (
        <div>
            <Container width="lg">
                <Grid container>
                    <Grid item md={6} xs={11}>
                        <Paper className={classes.card} elevation={6}>
                            <Typography
                                variant="h6"
                                className={classes.infoItems}
                            >
                                My Info &nbsp;{" "}
                                <CheckCircleOutlineIcon
                                    className={classes.green}
                                />
                            </Typography>
                            <Typography
                                variant="h5"
                                className={classes.infoItems}
                            >
                                <PermIdentityIcon
                                    color="primary"
                                    fontSize="medium"
                                />{" "}
                                &nbsp; Hello Admin
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.infoItems}
                            >
                                <PermIdentityIcon
                                    color="primary"
                                    fontSize="small"
                                />{" "}
                                &nbsp; Your IP:{" "}
                                {details && details.IPv4
                                    ? details.IPv4
                                    : "Disable ad blocker "}
                            </Typography>

                            <Divider />
                        </Paper>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                        <Paper className={classes.card} elevation={6}>
                            <Typography align="center">
                                {" "}
                                <PersonIcon className={classes.heart} />{" "}
                            </Typography>
                            <Typography variant="h6" align="center">
                                {" "}
                                No of Users{" "}
                            </Typography>
                            <Typography align="center" variant="subtitle2">
                                {users && users.length}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <br />
            {/* <Container> */}
            <div style={{ width: "98vw", padding: "0 1rem" }}>
                <Typography align="center" variant="h2">
                    Users
                </Typography>
                <Users />
            </div>
            {/* </Container> */}
        </div>
    );
}

export default Dashboard;
