export const CREATE_BLOG_REQUEST = 'CREATE_BLOG_REQUEST'
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS'
export const CREATE_BLOG_FAILED = 'CREATE_BLOG_FAILED'


export const GET_ALL_BLOGS_REQUEST = 'GET_ALL_BLOGS_REQUEST'
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS'
export const GET_ALL_BLOGS_FAILED = 'GET_ALL_BLOGS_FAILED'


export const DELETE_BLOG_REQUEST = 'DELETE_BLOG_REQUEST'
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS'
export const DELETE_BLOG_FAILED = 'DELETE_BLOG_FAILED'

export const GET_SINGLE_BLOG_REQUEST = 'GET_SINGLE_BLOG_REQUEST'
export const GET_SINGLE_BLOG_SUCCESS = 'GET_SINGLE_BLOG_SUCCESS'
export const GET_SINGLE_BLOG_FAILED = 'GET_SINGLE_BLOG_FAILED'

export const UPDATE_BLOG_REQUEST = 'UPDATE_BLOG_REQUEST'
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS'
export const UPDATE_BLOG_FAILED = 'UPDATE_BLOG_FAILED'