import styled from "styled-components";



export const BlogUploadContainer = styled.div`
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 200px;
    // border:1px solid black;
    // box-shadow: 0 4px 5px 2px rgba(0,0,0,.3) !important;
    // background:red;
`

export const FeatureImageContainer = styled.div`
    width: 100%;
    height: 30rem;
    margin-bottom: 5rem;
`


export const FeatureImage = styled.img`
    width: 100%;
    height: 100%;
`


export const MetaArea = styled.div`
    width: 100%;
    height: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5rem;
`

export const MetaKeywordsArea = styled.div`
    width: 50%;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding-left: 5rem; */
`

export const InputArea = styled.div`
    margin-bottom: 5rem;
    /* padding-left: 5rem; */
`

export const TagArea = styled.div`
    width: 100%;
    height: auto;
    overflow-y:auto;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        ...
  }
    /* padding-left: 5rem; */
`

export const MetaDescriptionArea = styled.div`
    width: 50%;
    height: 30rem;
`


export const Header = styled.div`
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5rem;
`

export const TitleArea = styled.div`
    width: 100%;
    height: 30rem;
    display: flex;
`


export const DescriptionArea = styled.div`
    width: 100%;
    height: 30rem;
`



export const SubmitButtonArea = styled.div`
    width: 100%;
    height: auto;
    text-align:center;
`