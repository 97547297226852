import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import {logoutAdmin} from "../../redux/actions/adminAction"
import navbarLogo from "../../assets/MantraLogo.png";
import MenuIcon from '@material-ui/icons/Menu';
// IMPORTING THE STYLED COMPONENTS

import {
    Nav,
    NavbarContainer,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemBtn,
} from './Navbar.elements'

// import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib'
import { Button } from '../../globalStyles'

// STATE MANAGEMENT FOR THE NAVBAR

const Navbar = () => {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const dispatch = useDispatch();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen]=useState(false)

    

    const handleClick = () => setClick(!click)



    const logoutHandler = () =>{
        dispatch(logoutAdmin())
    }

    const showButton = () => {
        console.log(window.innerWidth);
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }
    


    useEffect(() => {
        showButton()
    }, [])

    window.addEventListener('resize', showButton)
    return (
        // STRUCTURE OF THE NAVBAR

        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>

                    <NavbarContainer>
                        {/* NAVBAR LOGO */}
                        <Link to="/" >
                            <img
                            src={navbarLogo}
                            loading="lazy"
                            alt=""
                            style={{ background: "none", height: "12rem", marginTop:"-2.5rem" }}
                            />
                        </Link>
                        {/* NAVBAR ITEMS */}
                        <NavMenu click={click}>

                            <NavItem>
                                <NavLinks onClick={handleClick} to="/">
                                    Change Password
                                </NavLinks>
                            </NavItem>
                                {location.pathname  === "/blogs" ?                             
                                <NavItem>
                                <NavLinks onClick={handleClick} to="/add-blog">
                                    Upload Blog
                                </NavLinks>
                            </NavItem>  :  ""}
                            <NavItem>
                                <NavLinks onClick={handleClick} to="/blogs">
                                    Blogs
                                </NavLinks>
                            </NavItem>
                            <NavItemBtn>
                                {button ? (
                                        <Button
                                        onClick={logoutHandler}
                                         primary>LOGOUT</Button>
                                )  : (
                                        <Button
                                        onClick={logoutHandler}
                                         fontBig primary>
                                            LOGOUT
                                        </Button>
                                ) }
                            </NavItemBtn>
                        </NavMenu>
                    </NavbarContainer>
                    
                    {(!button) &&
                    <div onClick={handleClick}>
                    <MenuIcon style={{fontSize: '3rem', padding:'0.2rem'}}/>
                    </div>
                    }
                    
                </Nav>
                
            </IconContext.Provider>
        </>
    )
}

export default Navbar
