import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    DELETE_MULTY_USER_REQUEST,
    DELETE_MULTY_USER_SUCCESS,
    DELETE_MULTY_USER_FAILED,
    BLOCK_USER_REQUEST,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAILED,
} from '../constants/userConstants';

 ///    GET USERS REDUCER    ///
  
 const initialState = {
    loading: false,
    users: null,
    error: null,
    isAuthenticated:false
    }
export const getAllUsersReducer = (
    state = { initialState},
    action
  ) => {
    switch (action.type) {
      case GET_USER_REQUEST:
        return { ...state, loading: true}
      case GET_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          isAuthenticated:true

        }
      case GET_USER_FAILED:
        return {
          loading: false,
          error: action.payload,
          isAuthenticated:false
        }
      default:
        return state
    }


  }


 ///    DELETE SINGLE USERS REDUCER    ///
  
 const initialStateDeleteSingleUser = {
    loading: false,
    data: null,
    error: null,
    isAuthenticated:false
    }
export const deleteSingleUserReducer = (
    state = { initialStateDeleteSingleUser},
    action
  ) => {
    switch (action.type) {
      case DELETE_USER_REQUEST:
        return { ...state, loading: true}
      case DELETE_USER_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          isAuthenticated:true
        }
      case DELETE_USER_FAILED:
        return {
          loading: false,
          error: action.payload,
          isAuthenticated:false
        }
      default:
        return state
    }
  }
  
  
  
  ///    DELETE MULTY USERS REDUCER    ///
   
  const initialStateDeleteMultyUser = {
     loading: false,
     data: null,
     error: null,
     isAuthenticated:false
     }
 export const deleteMultyUserReducer = (
     state = { initialStateDeleteMultyUser},
     action
   ) => {
     switch (action.type) {
       case DELETE_MULTY_USER_REQUEST:
         return { ...state, loading: true}
       case DELETE_MULTY_USER_SUCCESS:
         return {
           loading: false,
           data: action.payload,
           isAuthenticated:true
         }
       case DELETE_MULTY_USER_FAILED:
         return {
           loading: false,
           error: action.payload,
           isAuthenticated:false
         }
       default:
         return state
     }
   }


   const initialStateBlockUser = {
    loading: false,
    data: null,
    error: null,
    isAuthenticated:false
    }
export const blockUserReducer = (
    state = { initialStateBlockUser},
    action
  ) => {
    switch (action.type) {
      case BLOCK_USER_REQUEST:
        return { ...state, loading: true}
      case BLOCK_USER_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          isAuthenticated:true
        }
      case BLOCK_USER_FAILED:
        return {
          loading: false,
          error: action.payload,
          isAuthenticated:false
        }
      default:
        return state
    }
  }