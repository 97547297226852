


  ///    CREATE BLOG  REDUCER    ///
  import {
    CREATE_BLOG_SUCCESS,
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_FAILED,
    GET_ALL_BLOGS_REQUEST,
GET_ALL_BLOGS_SUCCESS,
GET_ALL_BLOGS_FAILED,
DELETE_BLOG_REQUEST,
DELETE_BLOG_SUCCESS,
DELETE_BLOG_FAILED,
GET_SINGLE_BLOG_REQUEST,
GET_SINGLE_BLOG_SUCCESS,
GET_SINGLE_BLOG_FAILED,
UPDATE_BLOG_REQUEST,
UPDATE_BLOG_SUCCESS,
UPDATE_BLOG_FAILED,
  } from '../constants/blogConstants'


  const initialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    data:null
    }
export const createBlogReducer = (
    state = initialState,
    action
  ) => {
    switch (action.type) {
      case CREATE_BLOG_REQUEST:
        return { 
          ...state,
          loading: true,
          isAuthenticated: false,
          error: null,
        }
      case CREATE_BLOG_SUCCESS:
        return {
            ...state,
          loading: false,
          isAuthenticated: true,
          data: action.payload,
          error: null,
        }
      case CREATE_BLOG_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          data:null
        }
      default:
        return state
    }
  }



  const getAllBlogsInitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    blogs:null
    }
export const getAllBlogsReducer = (
    state = getAllBlogsInitialState,
    action
  ) => {
    switch (action.type) {
      case GET_ALL_BLOGS_REQUEST:
        return { ...state, loading: true}
      case GET_ALL_BLOGS_SUCCESS:
        return {
            ...state,
          loading: false,
          isAuthenticated: true,
          blogs: action.payload
        }
      case GET_ALL_BLOGS_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          blogs:null
        }
      default:
        return state
    }
  }



  const deleteBlogsInitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    data:null
    }
export const deleteBlogReducer = (
    state = deleteBlogsInitialState,
    action
  ) => {
    switch (action.type) {
      case DELETE_BLOG_REQUEST:
        return {
           ...state,
           loading: true,
           error: null,
           isAuthenticated:false,
           data:null
        }
      case DELETE_BLOG_SUCCESS:
        return {
            ...state,
            loading: true,
            error: null,
            isAuthenticated:true,
          data: action.payload
        }
      case DELETE_BLOG_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          data:null
        }
      default:
        return state
    }
  }


  
  const getSingleBlogInitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    data:null
    }
export const getSingleBlogReducer = (
    state = getSingleBlogInitialState,
    action
  ) => {
    switch (action.type) {
      case GET_SINGLE_BLOG_REQUEST:
        return {
           ...state,
           loading: true,
           error: null,
           isAuthenticated:false,
           data:null
        }
      case GET_SINGLE_BLOG_SUCCESS:
        return {
            ...state,
            loading: true,
            error: null,
            isAuthenticated:true,
          data: action.payload
        }
      case GET_SINGLE_BLOG_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          data:null
        }
      default:
        return state
    }
  }





  const UpdateBlognitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    data:null
    }
export const updateBlogReducer = (
    state = UpdateBlognitialState,
    action
  ) => {
    switch (action.type) {
      case UPDATE_BLOG_REQUEST:
        return { 
          ...state,
          loading: true,
          isAuthenticated: false,
          error: null,
        }
      case UPDATE_BLOG_SUCCESS:
        return {
            ...state,
          loading: false,
          isAuthenticated: true,
          data: action.payload,
          error: null,
        }
      case UPDATE_BLOG_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          data:null
        }
      default:
        return state
    }
  }