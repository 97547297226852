import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const initialState = {
  adminSignin: {
    adminInfo:
      JSON.parse(localStorage.getItem('adminInfo')) ||
      JSON.parse(sessionStorage.getItem('adminInfo'))
  }
}

const middleware = [thunk]
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store