import axios from 'axios'
import {
  ADMIN_SIGNIN_SUCCESS,
  ADMIN_SIGNIN_REQUEST,
  ADMIN_SIGNIN_FAILED,
  ADMIN_LOGOUT,
} from '../constants/adminConstants'


const localStorage = window.localStorage
const API =  process.env.REACT_APP_API




  // signIn admin
export const adminSignin = (email, password) => async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_SIGNIN_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
      const body = {
        email: email,
        password: password
      }
      const { data } = await axios.post(`${API}/admin/login`, body, config)
      dispatch({
        type: ADMIN_SIGNIN_SUCCESS,
        payload: data
      })
      localStorage.setItem('adminInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: ADMIN_SIGNIN_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  // admin logout
export const logoutAdmin = () => (dispatch) => {
    if (localStorage.getItem('adminInfo')) {
      localStorage.removeItem('adminInfo')
    }
    dispatch({
      type: ADMIN_LOGOUT
    })
  }
  