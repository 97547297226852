import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//IMPORT REDUX
import { getAllBlogs, deleteBlog } from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const GetBlogsLogic = () => {
  const [counter, setCounter] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const { blogs } = useSelector((state) => state.getAllBlogs);
  const {
    data: deleteBlogStatus,
    isAuthenticated,
    error,
  } = useSelector((state) => state.deleteBlog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && counter > 0 && !error) {
      toast.success(deleteBlogStatus && deleteBlogStatus.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        dispatch(getAllBlogs());
      }, 1000);
    }
  }, [counter, isAuthenticated, deleteBlogStatus,dispatch,error ]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error, counter]);

  // FUNCTION FOR DELETE SINGLE BLOG
  const deleteHandler = (slug) => {
    dispatch(deleteBlog(slug));
    setCounter(counter + 1);
  };


//   
const redirectHandler = (slug) =>{
    history.push(`blog/${slug}`)
}


// 
const copyHandler = (slug) => {
  navigator.clipboard.writeText(`https://www.manta.matricula.co.in/blog/${slug}`);
  toast.success("Link Copied to clipboard", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  setTimeout(() => {
    dispatch(getAllBlogs());
  }, 1000);
}
  return {
    blogs,
    deleteHandler,
    redirectHandler,
    copyHandler
  };
};
