import {
    Checkbox,
    Container,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Button,
} from "@material-ui/core";
import { CSVLink } from "react-csv";

import { useStyles } from "./user.style.js";
import { UserLogic } from "./userLogic";
function Users() {
    const classes = useStyles();
    const {
        csvData,
        users,
        userDelete,
        handleTick,
        multipleDeleteUsers,
        handleBlockUser,
    } = UserLogic();

    return (
        <div>
            <Container>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "#fff" }}
                    onClick={() => multipleDeleteUsers()}
                >
                    Delete Users
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", marginRight: "1rem" }}
                >
                    <CSVLink
                        filename="User-data"
                        style={{ color: "#fff", textDecoration: "none" }}
                        data={csvData}
                    >
                        Export User Data
                    </CSVLink>
                </Button>
            </Container>
            <Paper elevation={0} className={classes.spacing}>
                <TableContainer
                    style={{ borderRadius: "20px", overflow: "visible" }}
                >
                    <Table
                        className={classes.table}
                        // size="large"
                        padding="normal"
                    >
                        <TableHead className={classes.shadow}>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Select
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Email
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Registered Date
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Newsletter
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Delete User
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                        className={classes.headingFont}
                                    >
                                        Block User
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.shadow}>
                            {/*Map data from here*/}
                            {users &&
                                users.map((user, key) => (
                                    <TableRow hover elevation={6} key={key}>
                                        <TableCell>
                                            <Checkbox
                                                onClick={(e) =>
                                                    handleTick(user, e)
                                                }
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                                style={{
                                                    color: "#4B59F7",
                                                    "&.MuiChecked": {
                                                        color: "#4B59F7",
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="subtitle2">
                                                {user.username}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {new Date(user.RegisteredAt).toLocaleString()}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                            {user.status ? "Active" : "inactive"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                {user.newsletter ? "Subscribed" : "Unsubscribed"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() =>
                                                    userDelete(
                                                        user._id
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                            onClick={() => handleBlockUser(user)}
                                            >
                                                {user.blockStatus ? "Unblock" : "Block"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default Users;
