import { combineReducers } from 'redux'
import { adminSigninReducer } from './adminReducers';
import { createBlogReducer, deleteBlogReducer, getAllBlogsReducer, getSingleBlogReducer, updateBlogReducer } from './blogReducer';
import { getAllUsersReducer, deleteSingleUserReducer, deleteMultyUserReducer, blockUserReducer } from './userReducers'





const rootReducer = combineReducers({
    getAllUsers: getAllUsersReducer,
    deleteSingleUser: deleteSingleUserReducer,
    deleteMultyUser: deleteMultyUserReducer,
    blockUser: blockUserReducer,
    adminSignin: adminSigninReducer,
    createBlog: createBlogReducer,
    getAllBlogs: getAllBlogsReducer,
    deleteBlog: deleteBlogReducer,
    getSingleBlog: getSingleBlogReducer,
    updateBlog: updateBlogReducer,
})

export default rootReducer;
