export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILED = "DELETE_USER_FAILED"

export const DELETE_MULTY_USER_REQUEST = "DELETE_MULTY_USER_REQUEST"
export const DELETE_MULTY_USER_SUCCESS = "DELETE_MULTY_USER_SUCCESS"
export const DELETE_MULTY_USER_FAILED = "DELETE_MULTY_USER_FAILED"

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST"
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS"
export const BLOCK_USER_FAILED = "BLOCK_USER_FAILED"