import React from "react";
import "react-quill/dist/quill.snow.css";
import Editor from "../../components/BlogEditor/Editor";
import { UploadBlogLogic } from "./UploadBlogLogic";
import { Chip, makeStyles, Paper, TextField } from "@material-ui/core";
import featureImage from "../../assets/featureImage.jpg";
import { Button } from "../../globalStyles";
import { ToastContainer } from 'react-toastify';

import {
  BlogUploadContainer,
  FeatureImageContainer,
  FeatureImage,
  MetaArea,
  MetaKeywordsArea,
  InputArea,
  MetaDescriptionArea,
  TagArea,
  Header,
  TitleArea,
  DescriptionArea,
  SubmitButtonArea,
} from "./UploadBlog.Element";

export default function UploadBlog() {
  const {
    body,
    setBody,
    photo,
    handleDelete,
    metaTags,
    metaTagsHandler,
    metaKeyWord,
    setMetaKeyWord,
    handleFile,
    seleteFile,
    file,
    setFile,
    metaDescription,
    setMetaDescription,
    blogDescription,
    setBlogDescription,
    blogTitle,
    setBlogTitle,
    blogSubmitHandler
  } = UploadBlogLogic();
  const classes = useStyles();
  return (
    <>
      <BlogUploadContainer>
        <Paper className={classes.PaperStyle} elevation={3}>
          <FeatureImageContainer>
            <FeatureImage src={photo || featureImage} />
              <input
                id="fileUploadButton"
                name="fileUploadButton"
                type="file"
                accept='.png, .jpg, .jpeg'
                onChange={(event) => handleFile(event)}
                hidden
                multiple={false}
              />
              {" "}
              <Button
              onClick={seleteFile}
              >Change Feature Image</Button>
          </FeatureImageContainer>
          <Header>
            <TitleArea>
              <TextField
                value={blogTitle}
                onChange={(e) => {
                  setBlogTitle(e.target.value);
                }}
                className={classes.TitleInput}
                id="outlined-basic"
                variant="outlined"
                label="Title"
              />
            </TitleArea>
            <DescriptionArea>
              <TextField
                value={blogDescription}
                onChange={(e) => {
                  setBlogDescription(e.target.value);
                }}
                className={classes.DescriptionInput}
                multiline
                rows={5}
                id="outlined-basic"
                variant="outlined"
                label=" Description"
              />
            </DescriptionArea>
          </Header>
          <Editor body={body} setBody={setBody} />
          <MetaArea>
            <MetaKeywordsArea>
              <InputArea>
                <TextField
                  value={metaKeyWord}
                  onChange={(e) => {
                    setMetaKeyWord(e.target.value);
                  }}
                  className={classes.metaKeyword}
                  id="outlined-basic"
                  variant="outlined"
                  label="Add Tags"
                />
                <Button onClick={metaTagsHandler}>Add Tags</Button>
              </InputArea>
              <TagArea>
                {metaTags &&
                  metaTags.map((currentElement) => {
                    return (
                      <Chip
                        key={currentElement}
                        className={classes.chips}
                        label={currentElement}
                        onDelete={() => handleDelete(currentElement)}
                        color="primary"
                      />
                    );
                  })}
              </TagArea>
            </MetaKeywordsArea>
            <MetaDescriptionArea>
              <TextField
                onChange={(e) => {
                  setMetaDescription(e.target.value);
                }}
                value={metaDescription}
                className={classes.metaDescription}
                multiline
                rows={5}
                id="outlined-basic"
                variant="outlined"
                label="Add Meta Description"
              />
            </MetaDescriptionArea>
          </MetaArea>
          <SubmitButtonArea>
            <Button
            onClick={blogSubmitHandler}
            >Submit</Button>
            <ToastContainer />
          </SubmitButtonArea>
        </Paper>
      </BlogUploadContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  PaperStyle: {
    padding: "2rem",
  },
  metaKeyword: {
    marginRight: "2rem",
  },
  metaDescription: {
    marginRight: "2rem",
    width: "100%",
    height: "10rem",
  },
  chips: {
    marginRight: "2rem",
    marginBottom: "2rem",
    fontSize: "1.5rem",
  },
  TitleInput: {
    width: "100%",
    marginBottom: "5rem",
    // width: '30rem',
  },
  DescriptionInput: {
    marginRight: "2rem",
    width: "100%",
    height: "10rem",
  },
}));
