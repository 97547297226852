import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    table: {
        width: "85vw",
        boxShadow: "10px 10px 5px #0002",
        overflow: "auto !important",
        display: "block",
        margin: "0 auto",
    },
    headingFont: {
        fontSize: "1.8rem",
    },
    spacing: {
        margin: "10vh auto",
        background: "#fff0",
    },
    invisible: {
        textDecoration: "none",
        // background: '#fff0',
    },
    shadow: {
        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
        fontSize: "1.2rem !important",
    },
}));
