import { useState,useEffect } from "react"
import featureImage from "../../assets/featureImage.jpg";
import { useParams } from "react-router-dom"
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { uploadImageToCloud } from "../../api/uploadImage.js";

//IMPORT REDUX

import {updateBlog} from "../../redux/actions/blogAction"
import { getSingleBlog } from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const EditBlogLogic = () =>{

    const [body, setBody] = useState("")
    const [blogTitle, setBlogTitle] = useState("")
    const [blogDescription, setBlogDescription] = useState("")
    const [photo, setPhoto] = useState(featureImage)
    const [fileName, setFileName] = useState("")
    const [metaKeyWord, setMetaKeyWord] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [metaTags, setMetaTags] = useState([])
    const [counter, setCounter] = useState(0)
    const [file,setFile] = useState(null)


    const history = useHistory();
    const dispatch = useDispatch(); 

    const {  isAuthenticated,error } = useSelector((state) => state.updateBlog)
    const {
        data: Blog,
      } = useSelector((state) => state.getSingleBlog);


      const {slug} = useParams();

    // STORE FILE
    const handleFile = async (e) => {

      const file = e.target.files[0]
      setFile(file)
      setFileName(file.name)
      const imgUrl = URL.createObjectURL(file)
      console.log(imgUrl);
      setPhoto(imgUrl)
      console.log(file);
      console.log(fileName);
    }

      useEffect(() => {
        dispatch(getSingleBlog(slug));
      }, [dispatch,slug]);


      useEffect(() => {
          if(Blog){
              setBody(Blog && Blog.body.markdown);
              setBlogTitle(Blog && Blog.body.title);
              setPhoto(Blog && Blog.body.featureImage);
              setBlogDescription(Blog && Blog.body.description);
              setMetaDescription(Blog && Blog.body.metaDescription);
              setMetaTags(Blog && Blog.body.metaDescription);
              setMetaTags(Blog && Blog.body.metaKeywords.split(","))
          }
      }, [Blog]);
    
    useEffect(() => {
      if(error){
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    }, [error])


    useEffect(() => {
      if(isAuthenticated && counter > 0){ 
        toast.success("Blog Updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setCounter(0)
          setTimeout(() => {
            history.push("/blogs")
          }, 5000);
      }
    }, [isAuthenticated,counter,history])


    // SHOW WARNINGS BEFORE RELOAD THE PAGE
    // window.onbeforeunload = function() {
    //     return "Data will be lost if you leave the page, are you sure?";
    //   };

    //   ADD META TAGS
    const metaTagsHandler = () =>{
        setMetaTags([...metaTags, metaKeyWord])
        setMetaKeyWord("")
        }

        //  REMOVE META TAGS
    const handleDelete = (e) =>{
        setMetaTags(metaTags.filter(item => item !== e))
        setPhoto("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM52o3Lq1x8l8TMs2vHxopWkMJhgM_gKh85g&usqp=CAU")
    }

//   OPEN FILE MANAGER  FOR SELETE  FEATURE IMAGE
  const seleteFile = () =>{
      const file = document.querySelector("#fileUploadButton");
      file.click()
  }

  // SUBMIT BLOG HANDLER WITH VALIDATION
  const blogSubmitHandler = async () =>{
    let imgUrl;
    if(file !== null && body && metaTags && metaDescription && blogTitle && blogDescription){
      const res = await uploadImageToCloud(file, 'blog')
      if(res.success){
          imgUrl = res.data.Location
      }
    }
    if(body && metaTags && metaDescription && blogTitle && blogDescription){
      dispatch(updateBlog(blogTitle, blogDescription, imgUrl ? imgUrl : photo, body, metaDescription, metaTags.toString(),Blog && Blog.body.slug))
      if(!error){
        setCounter(counter + 1);
      }

    }
    else{
      toast.warn('Complete the blog to Edit!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

  }

    return{
        body,
        setBody,
        photo,
        handleDelete,
        metaTags,
        metaTagsHandler,
        metaKeyWord,
        setMetaKeyWord,
        handleFile,
        seleteFile,
        metaDescription,
        setMetaDescription,
        blogTitle,
        setBlogTitle,
        blogDescription,
        setBlogDescription,
        blogSubmitHandler,
        Blog,
        file,
        setFile
    }
}