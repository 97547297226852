import axios from 'axios'
import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    DELETE_MULTY_USER_REQUEST,
    DELETE_MULTY_USER_SUCCESS,
    DELETE_MULTY_USER_FAILED,
    BLOCK_USER_REQUEST,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAILED,
} from '../constants/userConstants';

const API =  process.env.REACT_APP_API


///    GET USERS    ///
export const getAllUsers = () => async (dispatch) => {
    try {
      dispatch({
        type: GET_USER_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
  
      const { data } = await axios.get(`${API}/users`, config)
  
      dispatch({
        type: GET_USER_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_USER_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }


  ///    DELETE SINGLE USER    ///

export const deleteSingleUser = (id) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_USER_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
  
      const { data } = await axios.delete(`${API}/users/${id}`, config)
  
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }
  
  
  ///    DELETE MULTY USER    ///

export const deleteMultyUser = (ids) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_MULTY_USER_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
  
      const { data } = await axios.post(`${API}/users/delete-many`, {ids},config)
  
      dispatch({
        type: DELETE_MULTY_USER_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: DELETE_MULTY_USER_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }
  
  
  
  ///    BLOCK  USER    ///

export const blockUser = (id) => async (dispatch) => {
    try {
      dispatch({
        type: BLOCK_USER_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
  
      const { data } = await axios.post(`${API}/users/block-user/${id}` ,config)
  
      dispatch({
        type: BLOCK_USER_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: BLOCK_USER_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }