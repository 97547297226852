import { useState,useEffect } from "react"
import featureImage from "../../assets/featureImage.jpg";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { uploadImageToCloud } from "../../api/uploadImage.js";

//IMPORT REDUX

import {createBlog} from "../../redux/actions/blogAction"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";



export const UploadBlogLogic = () =>{
    const [body, setBody] = useState("")
    const [blogTitle, setBlogTitle] = useState("")
    const [blogDescription, setBlogDescription] = useState("")
    const [photo, setPhoto] = useState(featureImage)
    const [fileName, setFileName] = useState("")
    const [file,setFile] = useState(null)
    const [metaKeyWord, setMetaKeyWord] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [metaTags, setMetaTags] = useState([])
    const [counter, setCounter] = useState(0)


    const history = useHistory();
    const dispatch = useDispatch(); 

    const {  isAuthenticated,error } = useSelector((state) => state.createBlog)
    
    useEffect(() => {
      if(error){
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    }, [error])


    useEffect(() => {
      if(isAuthenticated && counter > 0){ 
        toast.success("Blog Added successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setCounter(0)
          setTimeout(() => {
            history.push("/blogs")
          }, 5000);
      }
    }, [isAuthenticated,counter,history])


    // SHOW WARNINGS BEFORE RELOAD THE PAGE
    // window.onbeforeunload = function() {
    //     return "Data will be lost if you leave the page, are you sure?";
    //   };

    //   ADD META TAGS
    const metaTagsHandler = () =>{
        setMetaTags([...metaTags, metaKeyWord])
        setMetaKeyWord("")
        }

        //  REMOVE META TAGS
    const handleDelete = (e) =>{
        setMetaTags(metaTags.filter(item => item !== e))
        setPhoto("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM52o3Lq1x8l8TMs2vHxopWkMJhgM_gKh85g&usqp=CAU")
    }

      // STORE FILE
  const handleFile = async (e) => {

    const file = e.target.files[0]
    setFile(file)
    setFileName(file.name)
    const imgUrl = URL.createObjectURL(file)
    console.log(imgUrl);
    setPhoto(imgUrl)
    console.log(file);
    console.log(fileName);
  }

//   OPEN FILE MANAGER  FOR SELETE  FEATURE IMAGE
  const seleteFile = () =>{
      const file = document.querySelector("#fileUploadButton");
      file.click()
  }

  // SUBMIT BLOG HANDLER WITH VALIDATION
  const blogSubmitHandler = async () =>{
    let imgUrl;
    if(file !== null && body && metaTags && metaDescription && blogTitle && blogDescription){
      const res = await uploadImageToCloud(file, 'blog')
      if(res.success){
          imgUrl = res.data.Location
      }
    }
    if(body && metaTags && metaDescription && blogTitle && imgUrl && blogDescription){
      dispatch(createBlog(blogTitle, blogDescription, imgUrl, body, metaDescription, metaTags.toString()))
      if(!error){
        setCounter(counter + 1);
      }

    }
    else{
      toast.warn('Complete the blog to sumbit!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

  }



    return {
        body,
        setBody,
        photo,
        handleDelete,
        metaTags,
        metaTagsHandler,
        metaKeyWord,
        setMetaKeyWord,
        handleFile,
        seleteFile,
        metaDescription,
        setMetaDescription,
        blogTitle,
        setBlogTitle,
        blogDescription,
        setBlogDescription,
        blogSubmitHandler,
        file,
        setFile
    }
}