import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from '@material-ui/icons/Delete';
import featureImage from "../../assets/featureImage.jpg";
import { ToastContainer } from 'react-toastify';
import { CardActionArea, Tooltip } from "@material-ui/core";
import { 
    BlogContainer,
    useStyles,
 } from "./GetBlogs.element";
 import {GetBlogsLogic,} from "./GetBlogsLogic"

export default function GetBlogs() {
  const classes = useStyles();

    const {
        blogs,
        deleteHandler,
        redirectHandler,
        copyHandler
     } = GetBlogsLogic()

  return (
    <>
      <BlogContainer>
          <ToastContainer />
          {blogs && blogs.body.map((currentElement) =>{
              return (
<Card className={classes.root} key={currentElement._id}>
            <CardActionArea >
                
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                M
              </Avatar>
            }
            action={
                <Tooltip title="Delete">
                <IconButton
                onClick={() => deleteHandler(currentElement.slug)}
                 aria-label="delete">
                    <DeleteIcon />
                </IconButton>
                </Tooltip>
            }
            subheader={new Date(currentElement.createdAt).toLocaleString()}
          />
          <CardMedia
            onClick={() => redirectHandler(currentElement.slug)}
            className={classes.media}
            image={currentElement.featureImage || featureImage}
            title="Feat Image"
          />
          <CardContent
          onClick={() => redirectHandler(currentElement.slug)}
          >
            <Typography>{currentElement.title}</Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {currentElement.description.substring(0,200) + "..."}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
              <IconButton aria-label="share"onClick={copyHandler}>
                 <ShareIcon />
              </IconButton>
          </CardActions>
          </CardActionArea>

        </Card>
              )
          })}
      </BlogContainer>
    </>
  );
}
