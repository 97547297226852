import { useEffect, useState } from "react";

//IMPORT REDUX

import {adminSignin} from "../../redux/actions/adminAction"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


export const LoginLogic = () =>{

    // STATES FOR LOGIN
    const [alertMessage, setAlertMessage] = useState("");
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');

    
    const history = useHistory();
    const dispatch = useDispatch(); 

    const { adminInfo, isAuthenticate,error } = useSelector((state) => state.adminSignin)
    useEffect(() => {
        if(adminInfo){
          history.push("/")
        }
    
      }, [adminInfo, history])

      useEffect(() => {
        if(error){
            setAlertMessage(error);
        }
      }, [error,setAlertMessage])

        // LOGIN HANDLER FUNCTION

    function loginHandler (){
        if(passwordValue && emailValue){
                dispatch(adminSignin(emailValue, passwordValue));
                if(isAuthenticate){
                    history.push("/")
            }
        }
        else{
            setAlertMessage("Please provide proper credentials!");
        }
    }

    return{
        alertMessage,
        loginHandler,
        emailValue,
        setEmailValue,
        passwordValue,
        setPasswordValue
    }
}