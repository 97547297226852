import { Avatar, CardHeader, Chip, Typography } from '@material-ui/core';
import featureImage from "../../assets/featureImage.jpg";
import {Button} from "../../globalStyles"
import InnerHTML from 'dangerously-set-html-content'
import {BlogLogic} from "./BlogLogic"

import React from 'react';
import { 
    BlogContainer,
    FeatureImageContainer,
    TitleContainer,
    Heading,
    LabelsArea,
    AuthorDetails,
    FeatureImage,
    SubHeading,
    Author,
    BlogBody
 } from './Blog.element';

export default function index() {

    const {
        Blog,
        redirectHandler
    } = BlogLogic();
  return (
    <>
        <BlogContainer >
            <TitleContainer>
                <Heading >{Blog && Blog.body.title}</Heading> 
                <SubHeading>
                    <Author >
                        <LabelsArea>
                            {Blog && Blog.body.metaKeywords.split(",").map((e, index) =>{
                                return (
                                    index < 5 ?  <Chip label={e} style={{marginRight:"15px"}} /> : "" 
                                )
                            })}
                           
                        </LabelsArea>
                        <AuthorDetails >
                            <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe">
                                        M
                                    </Avatar>
                                    }
                                    title="Mantra"
                                    subheader={`${new Date(Blog && Blog.body.createdAt).toLocaleString()} || ${Math.floor(Blog && Blog.body.markdown.split(' ').length/350)} min read*`}
                                />
                        </AuthorDetails>
                    </Author>
                    <Button onClick={() => redirectHandler(Blog && Blog.body.slug)}> 
                        EDIT BLOG
                    </Button>
                </SubHeading>
            </TitleContainer>
            <FeatureImageContainer >
                            <FeatureImage src={Blog && Blog?.body?.featureImage || featureImage}/>
            </FeatureImageContainer>
            <BlogBody>
            <InnerHTML html={Blog && Blog.body.markdown} />
            </BlogBody>
        </BlogContainer >
    </>
    );
}
