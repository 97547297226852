import axios from 'axios'
import {
CREATE_BLOG_REQUEST,
CREATE_BLOG_SUCCESS,
CREATE_BLOG_FAILED,
GET_ALL_BLOGS_REQUEST,
GET_ALL_BLOGS_SUCCESS,
GET_ALL_BLOGS_FAILED,
DELETE_BLOG_REQUEST,
DELETE_BLOG_SUCCESS,
DELETE_BLOG_FAILED,
GET_SINGLE_BLOG_REQUEST,
GET_SINGLE_BLOG_SUCCESS,
GET_SINGLE_BLOG_FAILED,
UPDATE_BLOG_REQUEST,
UPDATE_BLOG_SUCCESS,
UPDATE_BLOG_FAILED,
} from "../constants/blogConstants"

const API =  process.env.REACT_APP_API



  // CREATE BLOG
  export const createBlog = (
    title,
    description,
    featureImage,
    markdown,
    metaDescription,
    metaKeywords
    ) => async (dispatch,getState) => {
    try {
      dispatch({
        type: CREATE_BLOG_REQUEST
      })

      const {
        adminSignin: { adminInfo }
      } = getState()
  
      const config = {
        'Content-Type': 'application/json',
        headers: {
            Authorization: `Bearer ${adminInfo.token}`
          }
      }
      const body = {
        title: title,
        description: description,
        featureImage:featureImage,
        markdown: markdown,
        metaDescription: metaDescription,
        metaKeywords: metaKeywords
      }
      const { data } = await axios.post(`${API}/blog/create-blog`, body, config)
      dispatch({
        type: CREATE_BLOG_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: CREATE_BLOG_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }



  // GET ALL BLOGS
  export const getAllBlogs = ( ) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_ALL_BLOGS_REQUEST
    })

    const {
      adminSignin: { adminInfo }
    } = getState()

    const config = {
      'Content-Type': 'application/json',
      headers: {
          Authorization: `Bearer ${adminInfo.token}`
        }
    }
    const { data } = await axios.get(`${API}/blog/list`, config)
    dispatch({
      type: GET_ALL_BLOGS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOGS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}


  // DELETE BLOG
  export const deleteBlog = (slug) => async (dispatch,getState) => {
  try {
    dispatch({
      type: DELETE_BLOG_REQUEST
    })

    const {
      adminSignin: { adminInfo }
    } = getState()

    const config = {
      'Content-Type': 'application/json',
      headers: {
          Authorization: `Bearer ${adminInfo.token}`
        }
    }
    const { data } = await axios.delete(`${API}/blog/delete-blog/${slug}`, config)
    dispatch({
      type: DELETE_BLOG_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: DELETE_BLOG_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}


  // GET SINGLE  BLOG
  export const getSingleBlog = (slug) => async (dispatch,getState) => {
    try {
      dispatch({
        type: GET_SINGLE_BLOG_REQUEST
      })
  
      const {
        adminSignin: { adminInfo }
      } = getState()
  
      const config = {
        'Content-Type': 'application/json',
        headers: {
            Authorization: `Bearer ${adminInfo.token}`
          }
      }
      const { data } = await axios.get(`${API}/blog/get-blog/${slug}`, config)
      dispatch({
        type: GET_SINGLE_BLOG_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_SINGLE_BLOG_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }








   // UPDATE BLOG
   export const updateBlog = (
    title,
  description,
  featureImage,
  markdown,
  metaDescription,
  metaKeywords,
  slug
  ) => async (dispatch,getState) => {
  try {
    dispatch({
      type: UPDATE_BLOG_REQUEST
    })

    const {
      adminSignin: { adminInfo }
    } = getState()

    const config = {
      'Content-Type': 'application/json',
      headers: {
          Authorization: `Bearer ${adminInfo.token}`
        }
    }
    const body = {
      title: title,
      description: description,
      featureImage:featureImage,
      markdown: markdown,
      metaDescription: metaDescription,
      metaKeywords: metaKeywords,
      slug
    }
    const { data } = await axios.put(`${API}/blog/update-blog/${slug}`, body, config)
    dispatch({
      type: UPDATE_BLOG_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_BLOG_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}