import { useEffect, useState } from "react";
import swal from 'sweetalert';


//import redux
import {getAllUsers, deleteSingleUser, deleteMultyUser, blockUser} from "../../redux/actions/userAction"
import { useDispatch, useSelector } from "react-redux";

export const UserLogic = () => {

    const dispatch = useDispatch(); 
    const [deleteUsers, setDeleteUsers] = useState({
        userIds: [],
      })

    const { users } = useSelector((state) => state.getAllUsers)


    useEffect(() => {
        dispatch(getAllUsers())
      }, [ dispatch])



      const handleTick = (user, event) => {
        if (event.target.checked) {
          setDeleteUsers((prevVal) => {
            return {
              userIds: [...prevVal.userIds, user._id],
            }
          })
        } else {
          var deleteUserIdIndex = deleteUsers.userIds.indexOf(user._id) //get id index
          deleteUsers.userIds.splice(deleteUserIdIndex, 1)
        }
      }


// single user delete
  const userDelete = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this user?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        // delete single user
        dispatch(deleteSingleUser(id));
        swal('Deleted!', 'User has been deleted!', 'success')

        setTimeout(function () {
            dispatch(getAllUsers())
        }, 1000)
      } else {
        swal('Your user is not deleted!')
      }
    })
  }


    // multiple user delete
    const multipleDeleteUsers = async () => {
        swal({
          title: 'Are you sure?',
          text: 'Are you sure that you want to delete these users?',
          icon: 'warning',
          buttons: true,
          dangerMode: true
        }).then(async (willDelete) => {
          if (willDelete) {
            // delete multiple user
        dispatch(deleteMultyUser(deleteUsers.userIds));

    
            swal('Deleted!', 'All Users has been deleted!', 'success')
    
            setTimeout(function () {
                dispatch(getAllUsers())
            }, 1000)
          } else {
            swal('Your user is not deleted!')
          }
        })
      }

      // block users
      const handleBlockUser = (user) =>{
        swal({
            title: 'Are you sure?',
            text: `Are you sure that you want to ${user.blockStatus ?"unblock" : "block" } this users?`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
          }).then(async (willDelete) => {
            if (willDelete) {
              // delete multiple user
          dispatch(blockUser(user._id));
              swal('Blocked!', 'Users has been blocked!', 'success')
              setTimeout(function () {
                  dispatch(getAllUsers())
              }, 1000)
            } else {
              swal(`Your user is not ${user.blockStatus ?"unblock" : "block" }`)
            }
      })
    }

    // CSV Export
    var csvData = [
        [
            "Name",
            "Email",
            "RegisterAt",
            "Status",
            "Newsletter",
        ],
    ];

    users &&
        users.map((user) => {
            return(
                csvData.push([
                    user.username,
                    user.email,
                    new Date(user.RegisteredAt).toLocaleString(),
                    user.status,
                    user.newsletter,
                ])
            )
        });

    return {
                csvData,
                users,
                userDelete,
                handleTick,
                multipleDeleteUsers,
                handleBlockUser
    };
};
