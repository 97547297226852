import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";


export const BlogContainer = styled.div`
    width: 100%;
    padding:auto;
    margin: 20px 190px ;
    display: flex;
    flex-wrap:wrap;
    
    @media(max-width: 851px){
      padding:10px;
      margin:0px;     
    @media(max-width: 1024px){
      padding:10px;
      margin:20px 50px;     
  }
`
export const useStyles = makeStyles((theme) => ({
    root: {
      width: 345,
      marginRight:'50px',
      marginBottom:'50px',
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: '#16375C'
    },
  }));