import {  useEffect } from "react";
import { useParams } from "react-router-dom"

import "react-toastify/dist/ReactToastify.css";

//IMPORT REDUX
import { getSingleBlog } from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


export const BlogLogic = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        data: Blog,
      } = useSelector((state) => state.getSingleBlog);


      const {slug} = useParams();
      

    useEffect(() => {
        dispatch(getSingleBlog(slug));
      }, [dispatch,slug]);
      document.title = (Blog && Blog.body.title) || "React App"


      // 
      const redirectHandler = (slug) =>{
        history.push(`/update-blog/${slug}`)
      }

    return {
        Blog,
        redirectHandler
    }
}