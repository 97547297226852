/*eslint-disable */
import { useState, useEffect } from "react";
export const DashboardLogic = () => {
    const [details, setDetails] = useState(null);
    //Get userIp address
    const getUserGeolocationDetails = () => {
        fetch(
            "https://geolocation-db.com/json/"
        )
            .then((response) => response.json())
            .then((data) => setDetails(data));
    };

    useEffect(() => {
        getUserGeolocationDetails();
    }, []);

    return {
        details,
    };
};
