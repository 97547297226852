import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { NavRoute } from './Layout/NavRoute';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import UploadBlog from './pages/UploadBlog';
import GetBlogs from './pages/GetBlogs';
import Blog from './pages/Blog';
import EditBlog from './pages/EditBlog';
import ScrollToTop from "./ScrollToTop"

function App() {


  const { adminInfo } = useSelector((state) => state.adminSignin)
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route exact path="/login" component={Login} />
        {adminInfo ?  <NavRoute exact path="/" component={Dashboard} /> : <Redirect to="/login" /> }
        {adminInfo ?  <NavRoute exact path="/add-blog" component={UploadBlog} /> : <Redirect to="/login" /> }
        {adminInfo ?  <NavRoute exact path="/blogs" component={GetBlogs} /> : <Redirect to="/login" /> }
        {adminInfo ?  <NavRoute exact path="/blog/:slug" component={Blog} /> : <Redirect to="/login" /> }
        {adminInfo ?  <NavRoute exact path="/update-blog/:slug" component={EditBlog} /> : <Redirect to="/login" /> }
      </Switch>
      <ScrollToTop />
    </Router>
  );
}

export default App;
