import styled from "styled-components";

export const BlogContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 50px 300px;
    padding-bottom:50px ;
    @media(max-width: 851px){
        padding:10px;
        margin:0px
    }
    `

export const TitleContainer = styled.div`
    width: 100%;
    minHeight: 300px;
    padding: 10px;
`

export const Heading = styled.h1`
    font-size: 48px;
    font-family: fell, Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight:400;
    margin-bottom:50px;
`

export const SubHeading = styled.div`
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items:center;
`

export const Author = styled.div`
    height: 100%;
`


export const LabelsArea = styled.div`
    width:100%;
    height: 50px;
    padding: 10px;
`

export const AuthorDetails = styled.div`
    width:100%;
`

export const FeatureImageContainer = styled.div`
    width: 100%;
    height: 400px;
`



export const FeatureImage = styled.img`
    width: 100%;
    height: 100%;
`

export const BlogBody = styled.div`
    width: 100%;
    height: auto;
    /* padding-top:100px; */
    padding: 100px 20px;
    display: grid;
    place-items:center;
    box-shadow: 0 4px 5px 2px rgba(0,0,0,.3) !important;
    overflow: hidden;
    

`